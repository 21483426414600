export const cards = [
    { id: 'modalporfolio1', title: 'Application UI Design', shortDesc: 'Dolar repellendus temporibus...', dynamicClass: 'cars' },
    { id: 'modalporfolio2', title: 'Furni furniture UI Design', shortDesc: 'Dolar repellendus temporibus...', dynamicClass: 'colors fruits' },
    { id: 'modalporfolio3', title: 'Mobile UI Design', shortDesc: 'Dolar repellendus temporibus...', dynamicClass: 'cars' },
    { id: 'modalporfolio4', title: 'Businesscard UI Design', shortDesc: 'Dolar repellendus temporibus...', dynamicClass: 'colors' },
    { id: 'modalporfolio5', title: 'Real estate UI Design', shortDesc: 'Dolar repellendus temporibus...', dynamicClass: 'cars animals' },
]

export const buttons = [
    { name: 'All', value: 'all' },
    { name: 'Products', value: 'cars' },
    { name: 'Web App', value: 'animals' },
    { name: 'Inetraction', value: 'fruits' },
    { name: 'Brand Identity', value: 'colors' },
]