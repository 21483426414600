import React from 'react'
import { blogs } from '../constants/blogContent'
import { CLIENT } from '../constants/environment'

export const Blog = () => {
    return (
        <section className="w-100 float-left blog-con padding-top padding-bottom position-relative" id="Blog">
            <div className="container">
                <div className="blog-inner-con position-relative">
                    <div className="generic-title text-center">
                        <h6>Latest News</h6>
                        <h2 className="mb-0">Blog & Articles</h2>
                    </div>
                    <div className="blog-box wow fadeInUp" >
                        <div className="row">
                            {blogs.map((blog, idx) => (
                                <div key={idx} className="col-lg-4">
                                    <div className="blog-box-item">
                                        <div className="blog-img">
                                            <a href="#" data-toggle="modal" data-target={`#blog-model-${idx + 1}`}>
                                                <figure className="mb-0">
                                                    <img src={`${CLIENT}image/blog-img-${idx + 1}.png`} alt="blog-img" className="img-fluid" />
                                                </figure>
                                            </a>
                                        </div>
                                        <div className="blog-content">
                                            <div className="blog-auteher-title">
                                                <span>By {blog.name}</span>
                                                <span className="float-lg-right">{blog.date}</span>
                                            </div>
                                            <a href="#" data-toggle="modal" data-target="#blog-model-1">
                                                <h4>{blog.title}</h4>
                                            </a>
                                            <p>{blog.shortDesc}</p>
                                            <a href="#" data-toggle="modal" data-target={`#blog-model-${idx + 1}`}>Read More</a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
