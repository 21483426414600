import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import './App.css'
import { CommonReducer } from './Context/CommonReducer';
import { CommonContext, initialValues } from './Context/CommonContext';
import { useReducer } from 'react';
import { SnackBar } from './components/SnackBar';
import { Loader } from './components/Loader';

function App() {
  const [state, dispatch] = useReducer(CommonReducer, initialValues)

  return (
    <CommonContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path='/' index element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <SnackBar />
      <Loader />
    </CommonContext.Provider>
  );
}

export default App;
